.appStoresContainer {
  background-color: none;
  width: 100%;
  padding: 1rem 0.5rem 10rem 0.5rem;
  box-sizing: border-box;
}

div.appStoresBadgesContainer {
  width: 100%;
  text-align: center;
}

span.appStoresLabel {
  display: block;
  margin: auto;
  padding: 0.5rem 1rem;
  text-align: center;
  color: #dedede;
}

a.appStoresBadge {
  display: inline-block;
  height: 3rem;
  margin: 1rem 1rem;
}
